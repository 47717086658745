import { ISearchRequestSorters } from '@wix/client-search-sdk';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

export function encodeSortBy(sortBy: ISearchRequestSorters[]): string {
  return null;
}

export function decodeSortBy(raw: string): ISearchRequestSorters[] {
  if (!raw) {
    return DEFAULT_SEARCH_REQUEST.sortBy;
  }

  return [];
}
