import {
  IEncodedLocationSearchRequest,
  SearchQueryParams,
  IDecodeSearchPath,
  IEncodeSearchPath,
} from './location.types';
import { encodeSearchRequest } from './params/encodeSearchRequest';
import { decodeSearchRequest } from './params/decodeSearchRequest';

const PARAM_VALUE_DELIMITER = '-';

export const decodePath: IDecodeSearchPath = path => {
  const raw = path
    .split('/')
    .reduce<IEncodedLocationSearchRequest>((memo, p) => {
      const [key, ...value] = decodeURIComponent(p).split(
        PARAM_VALUE_DELIMITER,
      );

      memo[key] = value.join(PARAM_VALUE_DELIMITER);

      return memo;
    }, {});

  return decodeSearchRequest(raw);
};

const PATH_PARAMS_SORT = [
  SearchQueryParams.Query,
  SearchQueryParams.DocumentType,
  SearchQueryParams.Page,
  SearchQueryParams.SortBy,
  SearchQueryParams.Filters,
];

export const encodePath: IEncodeSearchPath = searchRequest => {
  const params = encodeSearchRequest(searchRequest);

  return Object.keys(params)
    .sort((a: SearchQueryParams, b: SearchQueryParams) => {
      return PATH_PARAMS_SORT.indexOf(a) - PATH_PARAMS_SORT.indexOf(b);
    })
    .map(param => `${param}${PARAM_VALUE_DELIMITER}${params[param]}`)
    .join('/');
};
