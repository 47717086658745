import { ISearchRequestPageNumber } from '@wix/client-search-sdk';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

export function encodePageNumber(page: ISearchRequestPageNumber): string {
  if (!page || page === DEFAULT_SEARCH_REQUEST.page) {
    return null;
  }

  return page.toString();
}

export function decodePageNumber(raw: string): ISearchRequestPageNumber {
  if (!raw) {
    return DEFAULT_SEARCH_REQUEST.page;
  }

  const decoded = parseInt(raw, 10);
  const pageNumber = decoded > 0 ? decoded : DEFAULT_SEARCH_REQUEST.page;

  return pageNumber;
}
