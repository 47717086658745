import { encodeDocumentType, decodeDocumentType } from './documentType';
import { encodePageNumber, decodePageNumber } from './page';
import { encodeFilters, decodeFilters } from './filters';
import { encodeSortBy, decodeSortBy } from './sortBy';

export * from './documentType';
export * from './page';
export * from './filters';
export * from './sortBy';

export const encoders = {
  encodeDocumentType,
  encodePageNumber,
  encodeFilters,
  encodeSortBy,
};

export const decoders = {
  decodeDocumentType,
  decodePageNumber,
  decodeFilters,
  decodeSortBy,
};
