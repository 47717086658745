import { SearchDocumentType } from '@wix/client-search-sdk';

const ENCODING_MAP: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'all',
  [SearchDocumentType.Pages]: 'pages',
  [SearchDocumentType.Products]: 'products',
  [SearchDocumentType.Blogs]: 'blogs',
  [SearchDocumentType.Forums]: 'forums',
  [SearchDocumentType.Bookings]: 'bookings',
};

const DECODING_MAP = Object.keys(ENCODING_MAP).reduce(
  (acc, k: SearchDocumentType) => {
    acc[ENCODING_MAP[k]] = k;

    return acc;
  },
  {} as Record<string, SearchDocumentType>,
);

export function encodeDocumentType(documentType: SearchDocumentType): string {
  if (documentType === SearchDocumentType.All) {
    return null;
  }

  const serialized = ENCODING_MAP[documentType];
  if (serialized === undefined) {
    return null;
  }

  return serialized;
}

export function decodeDocumentType(raw: string): SearchDocumentType {
  if (!raw) {
    return null;
  }

  const documentType = DECODING_MAP[raw];
  if (documentType === undefined) {
    /**
     * We could not return default document type here
     * because we should show first available tab for user
     */
    return null;
  }

  return DECODING_MAP[raw];
}
