import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
  SearchQueryParams,
} from '../location.types';

import { decoders } from './serializers';
import { DEFAULT_SEARCH_REQUEST } from './defaultSearchRequest';

export function decodeSearchRequest(
  params: IEncodedLocationSearchRequest,
): ILocationSearchRequest {
  const request: Partial<ILocationSearchRequest> = {
    documentType: decoders.decodeDocumentType(
      params[SearchQueryParams.DocumentType],
    ),
    page: decoders.decodePageNumber(params[SearchQueryParams.Page]),
    sortBy: decoders.decodeSortBy(params[SearchQueryParams.SortBy]),
    filters: decoders.decodeFilters(params[SearchQueryParams.Filters]),
  };

  const query = params[SearchQueryParams.Query] || DEFAULT_SEARCH_REQUEST.query;

  return {
    // Due to broken encoding in Location.to
    query: decodeURIComponent(decodeURIComponent(query)), // ¯\_(ツ)_/¯
    ...Object.keys(request).reduce((memo, key) => {
      if (request[key]) {
        memo[key] = request[key];
      }

      return memo;
    }, {}),
  };
}
