import { ISearchRequestFilters } from '@wix/client-search-sdk';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

export function encodeFilters(filters: ISearchRequestFilters[]): string {
  return null;
}

export function decodeFilters(raw: string): ISearchRequestFilters[] {
  if (!raw) {
    return DEFAULT_SEARCH_REQUEST.filters;
  }

  return [];
}
