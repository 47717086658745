import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
  SearchQueryParams,
} from '../location.types';

import { encoders } from './serializers';

export function encodeSearchRequest(
  searchRequest: ILocationSearchRequest,
): IEncodedLocationSearchRequest {
  const params = {
    [SearchQueryParams.Query]: encodeURIComponent(searchRequest.query || ''),
    [SearchQueryParams.DocumentType]: encoders.encodeDocumentType(
      searchRequest.documentType,
    ),
    [SearchQueryParams.Page]: encoders.encodePageNumber(searchRequest.page),
    [SearchQueryParams.SortBy]: encoders.encodeSortBy(searchRequest.sortBy),
    [SearchQueryParams.Filters]: encoders.encodeFilters(searchRequest.filters),
  };

  return Object.keys(params).reduce((memo, key) => {
    if (params[key]) {
      memo[key] = params[key];
    }

    return memo;
  }, {});
}
